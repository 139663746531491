import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../css/carousel.css';

const Carousel = ({ images, interval = 3000 }) => {
  const [activeIndex, setActiveIndex] = useState(0);


  const nextSlide = () => {
    if (images.length > 1) {
      setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }
  };

  const prevSlide = () => {
    if (images.length > 1) {
      setActiveIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    }
  };

  useEffect(() => {
    if (images.length > 1) {
      const autoPlayInterval = setInterval(nextSlide, interval);
      return () => {
        clearInterval(autoPlayInterval);
      };
    }
  }, [interval, images.length]);

  return (
    <div className="modern-carousel">
      <button onClick={prevSlide} className="modern-carousel__btn modern-carousel__btn--prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M15.5 3l-9 9 9 9"></path>
        </svg>
      </button>
      <div className="modern-carousel__image-wrapper">
        <img
          src={images[activeIndex]}
          alt={`Slide ${activeIndex}`}
          className="modern-carousel__img"
          loading="lazy"
        />
      </div>
      <button onClick={nextSlide} className="modern-carousel__btn modern-carousel__btn--next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M8.5 3l9 9-9 9"></path>
        </svg>
      </button>
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  interval: PropTypes.number,
};

export default Carousel;