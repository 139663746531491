import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchSubCategories, fetchCarouselImgsType } from '../utils/MainFunctionality';
import Carousel from '../components/Carousel';
import CategoryBox from '../components/CategoryBox';
import PromotionalBanner from '../components/PromotionalBanner';
import '../css/storehome.css';

const SchoolUniformsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [carouselImages, setCarouselImages] = useState([]);

  // Extract id from state or fallback to localStorage
  const id = location.state?.id || localStorage.getItem('selectedCategoryId');
  // console.debug("id here " + id)
  // Save id to localStorage if it's available in location.state
  useEffect(() => {
    if (location.state?.id) {
      localStorage.setItem('selectedCategoryId', location.state.id);
    }
  }, [location.state]);

  useEffect(() => {
    if (!id) {
      navigate('/');
    } 
    else{
      fetchSubcategories(id);
      fetchCarouselImages();
    }
  }, [id, navigate]);

  const fetchSubcategories = async (id) => {
    setLoading(true);
    try {
      const subcategories = await fetchSubCategories(id);
      setSubcategories(subcategories);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCarouselImages = async () => {
    try {
      const imageUrls = await fetchCarouselImgsType(3); // Assuming recordpos is 3
      setCarouselImages(imageUrls);
    } catch (err) {
      console.error('Error while fetching carousel images:', err);
    }
  };

  const handleCategoryClick = (category) => {
    // Save clicked category's id to localStorage
    localStorage.setItem('selectedSchoolUniformId', category.id);
    navigate(`/Daka-School${category.link}`, { state: { id: category.id, name: category.name, link: category.link } });
  };

  return (
    <div>
        <h2 className="sub-title">SHOP</h2>
      <Carousel images={carouselImages} />
      <div className="shop-section">
        <div className="categories">
          {loading ? (
            <p>Loading...</p>
          ) : (
            subcategories.map((category) => (
              <CategoryBox
                key={category.id}
                image={category.imageUrl}
                title={category.name}
                link={category.link}
                onClick={() => handleCategoryClick(category)}
              />
            ))
          )}
        </div>
        {/* <PromotionalBanner /> */}
      </div>
    </div>
  );
};

export default SchoolUniformsPage;
