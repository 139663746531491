/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Stack, Image } from 'react-bootstrap'; // Import the Container component from react-bootstrap
import '../css/footer.css'; // Import the CSS file for styling
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

const Footer = ({categories = []}) => {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    setCategoryList(categories);
  }, [categories]);

  return (
    <MDBFooter style={{ backgroundColor: '#272455' }} className='text-center text-lg-start text-white'>
    <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
      <div className='me-5 d-none d-lg-block'>
        <span>Get connected with us on social networks:</span>
      </div>

      <div>
        <a href='https://www.facebook.com/DakakidsClothing/' className='me-4 text-reset'>
          <MDBIcon fab icon="facebook-f" />
        </a>
        {/* <a href='' className='me-4 text-reset'>
          <MDBIcon fab icon="instagram" />
        </a> */}
        <a href='https://g.co/kgs/XNsfpR7' className='me-4 text-reset'>
          <MDBIcon fab icon="google" />
        </a>
        <a href='https://www.instagram.com/dakacollectionsltd?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' className='me-4 text-reset'>
          <MDBIcon fab icon="instagram" />
        </a>
      </div>
    </section>

    <section className=''>
      <MDBContainer className='text-center text-md-start mt-5'>
        <MDBRow className='mt-3'>
          <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4'>
              <MDBIcon icon="gem" className="me-3" />
              DAKA
            </h6>
            <p>
            Στο καταστημα DAKA  επι της οδου Γριβα Διγενη 52 και στο καταστημα DAKA επι της οδου Μακαριου και Σφακειων ( ΜΕΣΑ ΓΕΙΤΟΝΙΑ - απεναντι απο κρεαταγορα Μελης) πολυ μεγαλη ποικιλια σε σχολικη ενδυση. 
            ΓΙΑ ΠΛΗΡΟΦΟΡΙΕΣ ΜΟΝΟ ΤΗΛΕΦΩΝΙΚΩΣ ΣΤΟ 25 394245.
            </p>
          </MDBCol>
          <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
            {Array.isArray(categoryList) && categoryList.map((category) => (
                  <p key={category.id}>
                    <Link to={category.link} state={{ id: category.id }} className='text-reset'>
                      {category.name}
                    </Link>
                  </p>
                ))}
          </MDBCol>
          <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4'>FAQs</h6>
            <p>
              <Link to="/return-policy" className='text-reset'>
                RETURN POLICY
              </Link>
            </p>
            <p>
              <Link to="/about-us" className='text-reset'>
                ABOUT US
              </Link>
            </p>
          </MDBCol>
{/* 
          <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
            <p>
              <a href='#!' className='text-reset'>
                Pricing
              </a>
            </p>
            <p>
              <a href='#!' className='text-reset'>
                Settings
              </a>
            </p>
            <p>
              <a href='#!' className='text-reset'>
                Orders
              </a>
            </p>
            <p>
              <a href='#!' className='text-reset'>
                Help
              </a>
            </p>
          </MDBCol> */}

          <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
            <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
            <p>
              <MDBIcon icon="home" className="me-2" />
              Αχαρνών αρ. 6A, 4185 Ύψωνας, Λεμεσός
            </p>
            <p>
              <MDBIcon icon="envelope" className="me-3" />
              info@daka.com.cy
            </p>
            <p>
              <MDBIcon icon="phone" className="me-3" /> + 357 25394245
            </p>
            {/* <p>
              <MDBIcon icon="print" className="me-3" /> + 01 234 567 89
            </p> */}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>

    <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        <div>
          <span> Copyright © 2024 Daka Collections Ltd</span>
        </div>
        <div>
          <span>
            Designed & Developed by{' '}
            <a className='text-reset fw-bold' href='http://mmhsys.com/'>
              MMH Systems Development Ltd
            </a>
          </span>
        </div>
      </div>
  </MDBFooter>
  );
};

Footer.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })),
};


export default Footer;