import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../css/categorybox.css'; // Updated CSS import

const CategoryBox = ({ image, title, link, onClick }) => {
  return (
    <div className="category-box" onClick={onClick}>
      <img src={image} alt={title} className="category-image" loading="lazy" />
      <div className="category-title">{title}</div>
    </div>
  );
};

CategoryBox.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default CategoryBox;
